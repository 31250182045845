
  import { Component, Vue } from 'vue-property-decorator';
  import {
    ACTIVATE_COMPETITION,
    DEACTIVATE_COMPETITION,
    DELETE_COMPETITION,
    FETCH_COMPETITION_ITEM,
    UPDATE_COMPETITION
  } from '@/store/competitions';
  import CompetitionEdit from '@/components/CompetitionEdit.vue';
  import { FETCH_SPORTS } from '@/store/sports';
  import { FETCH_LEAGUES } from '@/store/leagues';
  import { SEARCH_PICKS } from '@/store/picks';

  @Component({
    components: { CompetitionEdit }
  })
  export default class CompetitionsDetails extends Vue {
    created() {
      this.$store.dispatch(FETCH_COMPETITION_ITEM, this.$route.params.id);

      this.$store.dispatch(FETCH_SPORTS, {
        is_supported: 1,
        with: ['supported_bet_types', 'supported_leagues'],
        all: 1
      });
      this.$store.dispatch(FETCH_LEAGUES, {
        is_supported: 1,
        all: 1
      });
      this.$store.dispatch(SEARCH_PICKS, {
        with: ['event.league', 'event.event_teams.team', 'user', 'odd.bet_type'],
        competition_id: this.$route.params.id
      });
    }

    public save() {
      if (!(this.$refs.competitionEdit as any).validate()) {
        return;
      }

      this.$store.dispatch(UPDATE_COMPETITION)
        .then(() => this.$router.push({ name: 'app.competitions.list' }));
    }

    public remove() {
      this.$store.dispatch(DELETE_COMPETITION)
        .then(() => this.$router.push({ name: 'app.competitions.list' }));
    }

    public deactivate() {
      this.$store.dispatch(DEACTIVATE_COMPETITION)
        .then(() => this.$store.dispatch(FETCH_COMPETITION_ITEM, this.$route.params.id));
    }

    public activate() {
      this.$store.dispatch(ACTIVATE_COMPETITION)
        .then(() => this.$store.dispatch(FETCH_COMPETITION_ITEM, this.$route.params.id));
    }
  }
